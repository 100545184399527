@charset "UTF-8";
/* Import lo mismo que news-detail-amp */
header {
  display: none; }

body {
  padding-top: 0 !important; }

a {
  text-decoration: none;
  color: inherit; }

.col-12.col-sm-4,
.highlighted-notes-list.two-column.interesting-notes,
.comments,
iframe,
.embed_cont.type_encuesta,
.footer,
.socials-share,
.person-image,
.survey,
.embed_options {
  display: none; }

.article-theme {
  font-size: 14px;
  background: #0289CB;
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 5px; }
  .article-theme a {
    color: #fff;
    text-decoration: none; }

.title {
  font-size: 40px;
  color: #000;
  margin-bottom: 20px; }

.preview {
  font-size: 20px;
  color: #333;
  font-weight: 500; }
  .preview p {
    font-size: 20px;
    color: #333;
    font-weight: 500; }

.note .share-quote {
  color: #0289CB;
  font-size: 1.6em;
  font-style: italic;
  font-family: "IBM Plex Sans","Helvetica Neue",Arial,sans-serif;
  margin: 30px 0;
  line-height: 1.2em;
  position: relative;
  display: inline-block; }

.note .body-content ol {
  list-style: none;
  counter-reset: li;
  margin-left: 30px;
  padding: 20px 0 20px 30px;
  border-top: 1px solid #9E9E9E;
  border-bottom: 1px solid #9E9E9E;
  margin-bottom: 30px;
  margin-left: 0; }

.section-detail-header:before {
  content: 'ámbito.com';
  margin-bottom: 20px;
  text-align: center;
  display: inline-block; }

main.container.note {
  left: 0 !important; }

.free-banner {
  display: none; }

.ranking-list {
  display: none; }

.section-aside-single-article {
  display: none; }

.alert-warning {
  display: none; }